interface IRegistry {
    [lang: string]: {
        [id: string]: string
    };
}

const registry: IRegistry = {
    ru: {
        SLOGAN: "нон-стоп и без рекламы",
        EMAIL_HINT: "Связь с автором",

        LOADING: "Загрузка",
        ERROR: "Ошибка",

        PLAYER_ERR: "Ошибка плеера",

        ERR_NO_MP3: "Воспроизведение MP3 не поддерживается. Попробуйте другой браузер."
    },

    en: {
        SLOGAN: "non-stop and no ads",
        EMAIL_HINT: "Contact the author",

        LOADING: "Loading",
        ERROR: "Error",

        PLAYER_ERR: "Player error",

        ERR_NO_MP3: "MP3 playback is not supported. Try a different browser."
    }

};

let currentLang = "ru";

function translate(id: string) {
    let result = registry[currentLang][id];

    if (result === undefined && currentLang !== "ru") {
        result = registry.en[id];
    }

    if (result === undefined) {
        result = "[" + id + "]";
    }

    return result;
}

function langFromDesiredLang(desire: string) {
    if (/^(ru|be|kk)\b/i.test(desire)) {
        return "ru";
    }

    return "en";
}

function setLang(desiredLang: string) {
    currentLang = langFromDesiredLang(desiredLang);
}

function register(id: string, lang: string, text: string) {
    registry[lang][id] = text;
}

export = {
    t: translate,
    setLang,
    register
} as const;
