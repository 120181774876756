import intl = require("./intl");
import { ITrackMetadata } from "./metadata";
import { IStateMachine, STATE } from "./sm";
import stream = require("./stream");
import trackEvent = require("./track-event");

declare global {
    const ANDROID_APP: boolean;
    const cordova: any;
}

declare const MusicControls: any;

const noop = $.noop;

let currentArtist = "";
let currentTitle = intl.t("LOADING") + "…";
let currentCoverartUrl = "";
let currentPlaying = false;

function updateNotification(callback?: () => void) {
    const data = [
        currentArtist,
        currentTitle,
        currentCoverartUrl,
        currentPlaying
    ];
    const prevPlaying = currentPlaying;
    MusicControls.create(data, () => {
        if (currentPlaying !== prevPlaying) {
            syncNotificationPlayPauseButton();
        }
        if (callback) {
            callback();
        }
    });
}

function syncNotificationPlayPauseButton() {
    MusicControls.updateIsPlaying(currentPlaying);
}

function trackMusicControlsEvent(name: string) {
    trackEvent("UI", "Android MusicControls " + name);
}

function initMusicControls(sm: IStateMachine) {
    function handleEvent(message: string) {
        switch (message) {
            case "play":
            case "media-button-play":
                sm.play();
                trackMusicControlsEvent("Play");
                break;
            case "pause":
            case "media-button-pause":
            case "becoming-noisy":
                sm.stop();
                trackMusicControlsEvent("Pause");
                break;
        }
    }

    sm.stateChanged.add(() => {
        currentPlaying = sm.state() === STATE.PLAYING;
        syncNotificationPlayPauseButton();
    });

    sm.metadataChanged.add((metadata: ITrackMetadata) => {
        currentArtist = metadata.artist;
        currentTitle = metadata.title;
        currentCoverartUrl = metadata.coverartUrl(true);
        updateNotification();
    });

    MusicControls.subscribe(handleEvent);
}

function createPlayer() {
    const multiPlayer = (navigator as any).multiPlayer;
    const statusChanged = $.Callbacks();

    let initialized = false;
    let initializing = false;

    function handleCallback(s: string) {
        switch (s) {
            case "LOADING":
                statusChanged.fire("buffering");
                break;
            case "STARTED":
                statusChanged.fire("playing");
                break;
            case "STOPPED":
            case "STOPPED_FOCUS_LOSS":
                statusChanged.fire("stopped");
                break;
            case "ERROR":
                statusChanged.fire("error", "ANDROID_APP");
                break;
        }
    }

    function play() {
        if (initializing) {
            return;
        }

        if (!initialized) {
            initializing = true;
            updateNotification(() => {
                // Notification must be created before player
                // Player then uses it for its background service
                multiPlayer.initialize(handleCallback, noop, stream.url());
                multiPlayer.play();
                initializing = false;
                initialized = true;
            });
        } else {
            multiPlayer.play();
        }
    }

    function stop() {
        if (!initialized || initializing) {
            return;
        }

        multiPlayer.stop();
    }

    return {
        supportsVolume: false,
        supportsTimes: false,
        statusChanged,
        play,
        stop,
        setVolume: noop,
        getTimes: noop
    };
}

export = (sm: IStateMachine) => {
    if (!ANDROID_APP) {
        throw "N/A";
    }

    trackEvent(null, "pageview");

    cordova.plugin.utils.detectShazam();
    initMusicControls(sm);
    sm.init(createPlayer);
};
